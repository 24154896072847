var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main aboutBody"},[_c('h1',{staticClass:"aboutBody_title"},[_vm._v("关于本会")]),_c('hr'),_c('br'),_c('h3',{style:(_vm.isShow == 0 ? 'position:static;' : 'bottom:100px;'),on:{"click":function($event){return _vm.showBox(1)}}},[_vm._v(" 委员会介绍 "),_c('img',{attrs:{"src":require("../assets/ico/xIcon.png"),"width":"30px","height":"30px"}})]),_c('div',{ref:"boxHide1",staticClass:"boxHide",style:(_vm.isShow == 1 ? 'height:' + _vm.eleLength + 'px' : 'height:0px')},[_c('br'),_c('p',[_vm._v("开展体育文化调查研究、咨询服务和国际交流；")]),_c('p',[_vm._v("举办体育文化相关会议论坛和赛事活动（经属地批准后开展）；")]),_c('p',[_vm._v("开展体育文化体系业务培训；")]),_c('p',[_vm._v("开展体育文化合作和社会公益活动。")]),_c('br')]),_c('h3',{style:(_vm.isShow == 0 ? 'position:static;' : 'bottom:50px;'),on:{"click":function($event){return _vm.showBox(2)}}},[_vm._v(" 委员会成员介绍 "),_c('img',{attrs:{"src":require("../assets/ico/xIcon.png"),"width":"30px","height":"30px"}})]),_c('div',{ref:"boxHide2",staticClass:"boxHide",style:(_vm.isShow == 2 ? 'height:' + _vm.eleLength + 'px' : 'height:0px')},[_c('br'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_c('br')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"border-left":"3px solid #289aa6","padding":"0","color":"#289aa6"}},[_c('strong',[_vm._v("会长")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("王永刚 ")]),_vm._v("中国文化管理协会体育文化专业委员会会长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"border-left":"3px solid #289aa6","padding":"0","color":"#289aa6"}},[_c('strong',[_vm._v("副会长")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("陆刚 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("李兆林 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("高毅强 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("梁思源 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("赵明 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("杨庆 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("范建国 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("郑涛 ")]),_vm._v("中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("曹洪")]),_vm._v(" 中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("李登有")]),_vm._v(" 中国文化管理协会体育文化专业委员会副会长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"border-left":"3px solid #289aa6","padding":"0","color":"#289aa6"}},[_c('strong',[_vm._v("秘书长")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("李濛 ")]),_vm._v("中国文化管理协会体育文化专业委员会秘书长兼专家委员。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"border-left":"3px solid #289aa6","padding":"0","color":"#289aa6"}},[_c('strong',[_vm._v("副秘书长")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("张浩 ")]),_vm._v("中国文化管理协会体育文化专业委员会副秘书长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("张少杰 ")]),_vm._v("中国文化管理协会体育文化专业委员会副秘书长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("陆宁 ")]),_vm._v("中国文化管理协会体育文化专业委员会副秘书长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("杨超 ")]),_vm._v("中国文化管理协会体育文化专业委员会副秘书长。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"border-left":"3px solid #289aa6","padding":"0","color":"#289aa6"}},[_c('strong',[_vm._v("专家委员 注：以下根据拼音顺序排序")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("蔡艳峰、曹洪、陈昭、成兵、范建国、冯彬、高毅强、郭红、郭晖、李春生、李濛、李小媛、李兆林、梁思源、林小玉、林欣甫、刘芳、龙卫敏、瞿腊佳、肖长江、阎岭、杨庆、杨鲜婷、尹卫东、张卓妮、赵明、赵琪、郑涛")])])
}]

export { render, staticRenderFns }