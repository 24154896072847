<!-- 首页栏目页 -->
<template>
  <div class="main body_box">
    <div class="advertising main">
      <div class="swiper-container advertising_swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in articleList[0].data.articlePageInfo.list"
            :key="index"
            @click="goShow(item)"
          >
            <img
              class="advertising_img"
              :src="item.imagesSrc"
              :alt="item.title"
              
            />
          </div>
        </div>
        <!-- 分页器 -->
        <div class="swiper-pagination1"></div>
      </div>
    </div>
    <div class="article main">
      <div class="article_1">
        <div class="swiper-container article_swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="item in articleList[1].data.articlePageInfo.list"
              :key="item.id"
              @click="goShow(item)"
            >
              <img
                class="article_img"
                :src="item.imagesSrc"
                :alt="item.title"
                
              />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="article_2">
        <div class="article_title">
          <span>最新公告 </span>
          <router-link
            :to="{ path: '/columnList', query: { data: '最新公告', id: 3 } }"
            >更多>></router-link
          >
        </div>
        <div class="article_item">
          <div
            class="article_list"
            v-for="(item, index) in articleList[2].data.articlePageInfo.list"
            :key="index"
            @click="goShow(item)"
          >
            <p>{{ item.title }}</p>
            <span>{{ item.createTime.split(' ')[0] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="article articleTwo main">
      <div class="article_title">
        <span>资质介绍</span>
        <!-- <a href="#">更多>></a> -->
        <router-link to="councilLnfo">更多>></router-link>
      </div>
      <div class="card">
        <p>
          {{ logoStr }}
        </p>
        <img :src="logoImg" alt="" />
      </div>
    </div>
    <div class="article articleTwo main">
      <div class="article_title">
        <span>赛事信息 </span>
        <router-link
          :to="{ path: '/columnList', query: { data: '赛事信息', id: 4 } }"
          >更多>></router-link
        >
      </div>
      <div class="article_item">
        <div
          class="article_list"
          v-for="(item, index) in articleList[3].data.articlePageInfo.list"
          :key="index"
          @click="goShow(item)"
        >
          <p>{{ item.title }}</p>
          <span>{{ item.createTime.split(' ')[0] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/js/swiper.js";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  name: "HelloHome",
  data() {
    return {
      logoImg:'',
      logoStr:'',
      articleList: [
        { data: { articlePageInfo: { list: [] } } },
        { data: { articlePageInfo: { list: [] } } },
        { data: { articlePageInfo: { list: [] } } },
        { data: { articlePageInfo: { list: [] } } },
        { data: { articlePageInfo: { list: [] } } },
      ],
    };
  },
  created() {
    this.$axios
      .all([this.inquire(1), this.inquire(2), this.inquire(3), this.inquire(4)])
      .then((res) => {
        this.articleList = res;
        this._initSwiper();
      })
      .catch((err) => {
        this.$message.error("网络错误" + err);
      });
      let data = new FormData();
      data.append("id", 1);
      this.$axios.post("api/workbench/article/queryArticleById", data).then((res)=>{
       this.logoStr= res.data.title
       this.logoImg=res.data.imagesSrc
      })
  },
  mounted() {},
  methods: {
    inquire(groupId) {
      let data = new FormData();
      data.append("title", "");
      data.append("startDate", "");
      data.append("endDate", "");
      data.append("pageNo", 1);
      data.append("pageSize", 10);
      data.append("columnId", groupId);
      return this.$axios.post(
        "api/workbench/article/queryArticleByConditionForPage",
        data
      );
    },
    goShow(item) {
      if (item.source.indexOf("http") == -1) {
        this.$router.push({ name: "showText", query: { data: item.id } });
      } else {
        window.open(item.source, "_blank");
      }
    },
    _initSwiper() {
      setTimeout(() => {
        new Swiper(".article_swiper", {
          direction: "horizontal", // 垂直切换选项
          //mousewheel: true, //滚轮
          autoplay: {
            //自动开始
            delay: 4000, //时间间隔
            disableOnInteraction: true, //*手动操作轮播图后不会暂停*
          },
          loop: true, // 循环模式选项

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true, // 分页器可以点击
          },
        });
        new Swiper(".advertising_swiper", {
          direction: "horizontal", // 垂直切换选项
          //mousewheel: true, //滚轮
          autoplay: {
            //自动开始
            delay: 5000, //时间间隔
            disableOnInteraction: true, //*手动操作轮播图后不会暂停*
          },
          loop: true, // 循环模式选项
          spaceBetween: 30,
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination1",
            clickable: true, // 分页器可以点击
            dynamicBullets: true,
          },
        });
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.article {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  height: 320px;
}

.article_1,
.article_2 {
  width: calc(50% - 5px);
  box-sizing: border-box;
}

.advertising_img {
  width: 100%;
  object-position: center center;
  border-radius: 5px;
  height: 320px;
}

.body_box {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.article_img {
  height: 320px;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  object-position: center center;
}

.article_title {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  background-color: #289aa6;
  padding: 10px;
  box-sizing: border-box;
  height: 35px;
}

.article_title span {
  display: block;
  color: #fff;
}

.article_title a {
  text-decoration: none;
  color: #333;
}

.article_2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.article_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.article_list {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px dashed #8b8b8b;
  cursor: pointer;
  padding: 0 10px;
}

.article_list:hover {
  background-color: #eee;
}

.article_list p {
  flex: 1;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.45rem;
}

.article_list span {
  font-size: 0.8rem;
  color: #8b8b8b;
}

.articleTwo {
  flex-direction: column;
}
.card {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.card img {
  width: 170px;
  height: 170px;
  margin: 10px 30px;
}
.card p {
  flex: 1;
  text-indent: 2em;
}
@media screen and (max-width: 1200px) {
  .article_img {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    object-position: center center;
  }
}

@media screen and (min-width: 1201px) {
}

@media screen and (max-width: 980px) {
  .article_1,
  .article_2 {
    width: 100%;
  }

  .article_2 {
    margin-top: 10px;
  }

  .advertising_img {
    width: 100%;
    object-fit: contain;
    object-position: center center;
    height: auto;
  }

  .article_img {
    height: 160px;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    object-position: center center;
  }

  .article_list p {
    flex: 1;
    font-size: 1rem;
    line-height: 2rem;
  }

  .article_img,
  .article {
    height: auto;
  }
  .card {
    flex-direction: column-reverse;
  }
}
</style>
