<!-- 关于委员会 -->
<template>
  <div class="main aboutBody">
    <h1 class="aboutBody_title">关于本会</h1>
    <hr />
    <br />
    <h3 @click="showBox(1)" :style="isShow == 0 ? 'position:static;' : 'bottom:100px;'">
      委员会介绍 <img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div class="boxHide" ref="boxHide1" :style="isShow == 1 ? 'height:' + eleLength + 'px' : 'height:0px'">
      <br />

      <p>开展体育文化调查研究、咨询服务和国际交流；</p>
      <p>举办体育文化相关会议论坛和赛事活动（经属地批准后开展）；</p>
      <p>开展体育文化体系业务培训；</p>
      <p>开展体育文化合作和社会公益活动。</p>
      <br />
    </div>
    <h3 @click="showBox(2)" :style="isShow == 0 ? 'position:static;' : 'bottom:50px;'">
      委员会成员介绍 <img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div class="boxHide" ref="boxHide2" :style="isShow == 2 ? 'height:' + eleLength + 'px' : 'height:0px'">
      <br />
      <p style="border-left: 3px solid #289aa6;padding: 0 ;color: #289aa6;"><strong>会长</strong></p>
      <p><strong>王永刚 </strong>中国文化管理协会体育文化专业委员会会长。</p>


      <p style="border-left: 3px solid #289aa6;padding: 0 ;;color: #289aa6;"><strong>副会长</strong></p>
      <p><strong>陆刚 </strong>中国文化管理协会体育文化专业委员会副会长。</p>
      <p><strong>李兆林 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>高毅强 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>梁思源 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>赵明 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>杨庆 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>范建国 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>郑涛 </strong>中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>曹洪</strong> 中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p><strong>李登有</strong> 中国文化管理协会体育文化专业委员会副会长兼专家委员。</p>
      <p style="border-left: 3px solid #289aa6;padding: 0;;color: #289aa6;"><strong>秘书长</strong></p>
      <p><strong>李濛 </strong>中国文化管理协会体育文化专业委员会秘书长兼专家委员。</p>
      <p style="border-left: 3px solid #289aa6;padding: 0;;color: #289aa6;"><strong>副秘书长</strong></p>
      <p><strong>张浩 </strong>中国文化管理协会体育文化专业委员会副秘书长。</p>
      <p><strong>张少杰 </strong>中国文化管理协会体育文化专业委员会副秘书长。</p>
      <p><strong>陆宁 </strong>中国文化管理协会体育文化专业委员会副秘书长。</p>
      <p><strong>杨超 </strong>中国文化管理协会体育文化专业委员会副秘书长。</p>
      <p style="border-left: 3px solid #289aa6;padding: 0;;color: #289aa6;"><strong>专家委员 注：以下根据拼音顺序排序</strong></p>
      <p><strong>蔡艳峰、曹洪、陈昭、成兵、范建国、冯彬、高毅强、郭红、郭晖、李春生、李濛、李小媛、李兆林、梁思源、林小玉、林欣甫、刘芳、龙卫敏、瞿腊佳、肖长江、阎岭、杨庆、杨鲜婷、尹卫东、张卓妮、赵明、赵琪、郑涛</strong></p>
      <br />
    </div>
    <!-- <h3 @click="showBox(3)" :style="isShow == 0 ? 'position:static;' : ''">
      组织章程<img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div
      class="boxHide"
      ref="boxHide3"
      :style="isShow == 3 ? 'height:' + eleLength + 'px' : 'height:0px'"
    >
      <br />
      <p>内容未定</p>
      <br />
    </div> -->
  </div>
</template>
<script>
export default {
  name: "abouT",
  data() {
    return {
      isShow: 1,
      eleLength: 0,
      oldeleLength: 0,
    };
  },
  mounted() {
    this.eleLength = this.$refs["boxHide1"].scrollHeight;
  },
  methods: {
    showBox: function (num) {
      if (this.isShow != num) {
        this.eleLength = this.$refs["boxHide" + num].scrollHeight;
        this.isShow = num;
      } else {
        this.isShow = 0;
      }
    },

  },
};
</script>
<style scoped>
.aboutBody {
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.boxHide {
  /* display: none; */
  height: 0;
  overflow: hidden;
}

.aboutBody_title {
  line-height: 70px;
  text-align: center;
}

/* .aboutBody img{
        display: block;
        margin: 0 auto;
    } */
.boxHide p {
  text-indent: 2em;
  line-height: 32px;
  padding: 0 40px;
}

.aboutBody h3 {
  font-size: 18px;
  line-height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 1px #000;
  cursor: pointer;
  border-left: 5px solid #289aa6;
}

.aboutBody h3 span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}





@media screen and (max-width: 980px) {
  .aboutBody h3 {
    padding: 0 20px;
  }

  .aboutBody p {
    text-indent: 2em;
    line-height: 32px;
    padding: 0 0px;
    color: #333;
  }

}
</style>
