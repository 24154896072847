<template>
  <div id="app">
    <INDEX></INDEX>
  </div>
</template>

<script>
import INDEX from "./components/index.vue";

export default {
  name: "App",
  components: {
    INDEX,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
hr {
  border: none;
  background-color: #289aa6;
  height: 3px;
}
#app {
  width: 100%;
}
body {
  background-color: #eee;
}
@media screen and (max-width: 1200px) {
  .VersionTip {
    display: block;
    background-color: #fdd889;
    color: #333;
    text-align: center;
    padding: 5px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1201px) {
  .main {
    margin: 0 auto;
    max-width: 1200px;
  }

  .VersionTip {
    display: none;
  }
}

@media screen and (min-width: 1401px) {
  .main {
    margin: 0 auto;
    max-width: 1400px;
  }
}
</style>
