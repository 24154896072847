<!-- 证书查询 -->
<template>
  <div class="main aboutBody">
    <h2 class="searchCert_h2">证书查询系统</h2>
    <el-card class="box-card" style="max-width: 500px; margin: 20px auto">
      <el-form :model="searchCert" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" label-width="90px" prop="name">
          <el-input
            v-model="searchCert.name"
            autocomplete="no"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件号" label-width="90px" prop="numberID">
          <el-input
            v-model="searchCert.numberID"
            autocomplete="no"
            placeholder="请输入居民身份证/护照/港澳台通行证"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom certBtn">
        <el-button plain @click="getCertificate()" v-loading="loading"
          >查 询</el-button
        >
      </div>
    </el-card>
    <el-dialog
      title="查询结果"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
      width="90%"
    >
      <div class="certCard_fasle" v-show="isData == false">未查询到证书</div>
      <div class="certCard_true" v-show="isData">
        <div class="true_card" v-if="isPingshu">
          <el-image
            class="certCard_img"
            :src="icon1"
            :preview-src-list="srcList1"
          >
          </el-image>
          <p>聘书</p>
        </div>
        <div class="true_card" v-if="isZhengshu">
          <el-image
            class="certCard_img"
            :src="icon2"
            :preview-src-list="srcList2"
          >
          </el-image>
          <p>证书</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="hideImg"
          type="primary"
          @click="downLoca('pingshu')"
          v-show="isData && isPingshu"
          >保存聘书</el-button
        >
        <el-button
          class="hideImg"
          type="primary"
          @click="downLoca('zhengshu')"
          v-show="isData && isZhengshu"
          >保存证书</el-button
        >
        <span class="footTip">长按图片保存</span>
        <el-button @click="closeDialog()" style="margin-left: 10px"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import certIcon from "../assets/ico/certIcon.png";
import certIcon2 from "../assets/ico/certIcon2.png";
import pingshu from "../assets/certificate/pingshu.png";
import zhengshu from "../assets/certificate/zhengshu.jpg";
import zhuanjiazhengshu from "../assets/certificate/zhuanjiazhengshu.jpg";
export default {
  name: "CertificateQuery",
  data() {
    return {
      dialogFormVisible: false,
      searchCert: {
        name: "",
        numberID: "",
      },
      //是否查到了证书
      isData: false,
      isZhengshu: false,
      isPingshu: false,
      icon1: certIcon,
      icon2: certIcon2,
      //聘书
      srcList1: [],
      //证书
      srcList2: [],
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        numberID: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    getCertificate() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          //打开证书面板
          this.postCertificate().then((res) => {
            if (JSON.stringify(res.data) == "{}") {
              this.isData = false;
            } else {
              this.isData = true;
              if (res.data.certificate != undefined) {
                this.isZhengshu = true;
                this.canvasZhengshu(res.data.certificate);
              }
              if (res.data.appointment != undefined) {
                this.isPingshu = true;
                this.cavasPingshu(
                  res.data.appointment,
                  res.data.certificate?res.data.certificate.imgUrl:false
                );
              }
            }
            //关闭加载动画
            this.dialogFormVisible = true;
            this.loading = false;
          });
        } else {
          return false;
        }
      });
      //打开加载动画
    },
    //发送查询请求
    postCertificate() {
      let data = new FormData();
      data.append("name", this.searchCert.name);
      data.append("idNo", this.searchCert.numberID);
      return this.$axios.post(
        "api/workbench/certificate/queryCertificateAndAppointment",
        data
      );
    },
    //绘制证书
     canvasZhengshu(data) {
      // 创建canvas元素
      const canvas = document.createElement("canvas");
      // 插入图片
      const img = new Image();
      const imgUrl = new Image();
      imgUrl.setAttribute("crossOrigin", "anonymous");
      if (data.professionalStandara == "专家考官") {
        img.src = zhuanjiazhengshu;

        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          // 渲染图片
          ctx.drawImage(img, 0, 0);

          // 添加文字
          ctx.font = "20px 宋体";
          ctx.fillStyle = "#000";
          ctx.fillText(data.name, this.centerXYZ(data.name, 370), 502);
          ctx.fillText(data.idNo, this.centerXYZ(data.idNo, 380), 582); // 文字内容和位置
          ctx.fillText(data.gender, this.centerXYZ(data.gender, 380), 660);
          ctx.fillText(data.cerNo, this.centerXYZ(data.cerNo, 380), 735);
          ctx.fillText(data.name, this.centerXYZ(data.name, 725), 300);
          ctx.fillText(data.nameEn?data.nameEn:data.name, this.centerXYZ(data.nameEn?data.nameEn:data.name, 935), 473);
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[0],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[0], 820),
            300
          );
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[1],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[1], 885),
            300
          );
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[2],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[2], 940),
            300
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[0],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[0],
              1025
            ),
            300
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[1],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[1],
              1085
            ),
            300
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[2],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[2],
              1140
            ),
            300
          );
          ctx.fillText(
            data.professionalStandara,
            this.centerXYZ(data.professionalStandara, 838),
            370
          );
          ctx.font = " 18px cursive";
          ctx.fillText(
            data.issueDate,
            this.centerXYZ(data.issueDate, 1095),
            748
          );
         
            imgUrl.src = "https://cscc.ac.cn" + data.imgUrl;
            // 导出图片
              imgUrl.onload = () => {
                ctx.drawImage(imgUrl, 300, 260, 140, 200);
              const dataURL = canvas.toDataURL("image/png");
              this.srcList2.push(dataURL);
              }
              imgUrl.onerror=()=>{
                 this.$message('未找到头像');
              const dataURL = canvas.toDataURL("image/png");
              this.srcList2.push(dataURL);
              }
             
           
          
        };
      } else {
        img.src = zhengshu; // 图片路径
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          // 渲染图片
          ctx.drawImage(img, 0, 0);

          // 添加文字
          ctx.font = "20px 宋体";
          ctx.fillStyle = "#000";
          ctx.fillText(data.name, this.centerXYZ(data.name, 330), 500);
          ctx.fillText(data.idNo, this.centerXYZ(data.idNo, 340), 575); // 文字内容和位置
          ctx.fillText(data.gender, this.centerXYZ(data.gender, 340), 650);
          ctx.fillText(data.cerNo, this.centerXYZ(data.cerNo, 340), 725);
          ctx.fillText(data.name, this.centerXYZ(data.name, 750), 305);
          ctx.fillText(data.nameEn?data.nameEn:data.name, this.centerXYZ(data.nameEn?data.nameEn:data.name, 960), 473);
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[0],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[0], 838),
            305
          );
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[1],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[1], 900),
            305
          );
          ctx.fillText(
            data.trainingDate.split("至")[0].split(".")[2],
            this.centerXYZ(data.trainingDate.split("至")[0].split(".")[2], 950),
            305
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[0],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[0],
              1030
            ),
            305
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[1],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[1],
              1090
            ),
            305
          );
          ctx.fillText(
            data.trainingDate.split("至")[1].split(".")[2],
            this.centerXYZ(
              data.trainingDate.split("至")[1].split(".")[2],
              1140
            ),
            305
          );
          ctx.fillText(
            data.professionalStandara,
            this.centerXYZ(data.professionalStandara, 838),
            370
          );
          ctx.font = " 18px cursive";
          ctx.fillText(
            data.issueDate,
            this.centerXYZ(data.issueDate, 1120),
            735
          );
        
              imgUrl.src = "https://cscc.ac.cn" + data.imgUrl;
              // 导出图片
              imgUrl.onload = () => {
              ctx.drawImage(imgUrl, 270, 260, 140, 200);
              const dataURL = canvas.toDataURL("image/png");
              this.srcList2.push(dataURL);
              }
              imgUrl.onerror=()=>{
                this.$message('未找到头像');
              const dataURL = canvas.toDataURL("image/png");
              this.srcList2.push(dataURL);
              }
              
        }
      }
    },
    //绘制聘书
    cavasPingshu(data, url) {
      // 创建canvas元素
      const canvas = document.createElement("canvas");
      // 插入图片
      const img = new Image();
      const imgUrl = new Image();
      imgUrl.setAttribute("crossOrigin", "anonymous");
      img.src = pingshu; // 图片路径
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        // 渲染图片
        ctx.drawImage(img, 0, 0);
        // 添加文字
        ctx.font = "bold 36px cursive";
        ctx.fillStyle = "#000";
        ctx.fillText(data.name, this.centerXYZ(data.name, 410), 655);
        ctx.fillText(data.name, this.centerXYZ(data.name, 1560), 670);
        ctx.fillText(data.idNo, this.centerXYZ(data.idNo, 1530), 790); // 文字内容和位置
        ctx.fillText(data.gender, this.centerXYZ(data.gender, 1590), 910);
        ctx.fillText(data.term, this.centerXYZ(data.term, 1510), 1030);
        ctx.fillText(data.cerNo, this.centerXYZ(data.cerNo, 1530), 1150);
        ctx.font = " 28px cursive";
        ctx.fillText(data.nameEn, this.centerXYZ(data.nameEn, 610), 945);
      if(url==false){
        imgUrl.src = "https://cscc.ac.cn/img/juryImages/" + data.name+data.idNo.substr(-4)+'.jpg';
      }else{
        imgUrl.src = "https://cscc.ac.cn" + url;
      }
          imgUrl.onload = () => {
            ctx.drawImage(imgUrl, 1490, 260, 200, 280);
            const dataURL = canvas.toDataURL("image/png");
            this.srcList1.push(dataURL);
          };
        imgUrl.onerror=()=>{
           this.$message('未找到头像');
          const dataURL = canvas.toDataURL("image/png");
            this.srcList1.push(dataURL);
        }
         
        // 导出图片
        
      };
    },
    centerXYZ(str, num) {
      return num - (str.length * 20) / 4;
    },
    closeDialog() {
      this.srcList1 = [];
      this.srcList2 = [];
      this.isData = false;
      this.isZhengshu = false;
      this.isPingshu = false;
      this.dialogFormVisible = false;
    },
    downLoca(str) {
      if (str == "zhengshu") {
        this.downloadImage(this.srcList2[0], "证书");
        this.closeDialog();
      } else if (str == "pingshu") {
        this.downloadImage(this.srcList1[0], "聘书");
        this.closeDialog();
      }
    },
    //下载图片
    base64ToBlob(base64) {
      const parts = base64.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    downloadImage(base64, str) {
      const blob = this.base64ToBlob(base64);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = str + ".png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
};
</script>
<style scoped>
.aboutBody {
  background: url(../assets/shuiyin.png) repeat #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.searchCert_h2 {
  text-align: center;
}

.certBtn {
  width: fit-content;
  margin: 0 auto;
}
.certCard_fasle {
  line-height: 60px;
  font-size: 18px;
  color: #545454;
  text-align: center;
}
.certCard_true {
  display: flex;
  justify-content: center;
}
.true_card {
  display: flex;
  box-shadow: 0px 0px 3px #333;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.true_card p {
  line-height: 30px;
  color: #333;
}
.certCard_img {
  width: 150px;
  height: 150px;
  display: block;
}
.footTip {
  display: none;
  font-size: 1rem;
  margin: 0 5px;
}

@media screen and (max-width: 980px) {
  .hideImg {
    display: none !important;
  }
  .footTip {
    display: inline-block !important;
  }
}
</style>
