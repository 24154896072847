<!-- 关于协会 -->
<template>
  <div class="main aboutBody">
    <h1 class="aboutBody_title">关于协会</h1>
    <hr />
    <br />
    <h3
      @click="showBox(1)"
      :style="isShow == 0 ? 'position:static;' : 'bottom:100px;'"
    >
      协会介绍 <img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div
      class="boxHide"
      ref="boxHide1"
      :style="isShow == 1 ? 'height:' + eleLength + 'px' : 'height:0px'"
    >
      <br />
      <p>
        中国文化管理协会（China Culture Administration
        Association，简称：CCAA）前身为中国文化管理学会，成立于1991年12月20日，登记证号为3834，为响应党的十七届六中全会和十八
        大关于建设文化强国的号召，适应政府管理部门的职能转变，我会经文化部、民政部批准更名为“中国文化管理协会”。我会是由从事文化管理、研究的机构，文化
        企事业单位和对我国文化管理领域、文化行业有贡献、有影响的管理者、经营者、专家学者自愿组成的全国性、专业性、非营利性社会组织，具有社团法人地位。按照中办、国办印发的关于《行业协会商会与行政机关脱钩总体方案》（中办发[2015]39号）和民政部、发改委印发的《关于做好全国性行业协会商会与行政机关脱钩试点工作的通知》（民发[2015]150号）要求，我会作为第一批行业协会脱钩试点单位于2016年10月完成各项脱钩任务。脱钩后，协会登记管理机关为民政部，党建领导机关为中央和国家机关工委。
      </p>
      <p>
        近年来，协会认真贯彻落实党中央、国务院重大决策部署，主动适应行业协会转型发展的新常态，本着服务会员、服务行业、服务社会的宗旨，发挥行业协会桥梁纽带作用，扎实推进慈善助学公益工程、智库建设智力工程和文化管理人才工程三大工程建设。2014-2018年，协会积极参与脱贫攻坚，连续五年开展慈善助学项目，共资助近两百名贫困大学生完成学业，累计资助现金208.5万元。2011年，协会被民政部评为“3A级全国性学术类社会组织”。2012年3月被文化部评为“文化部业务主管先进社会组织”。2018年被民政部授予3A级行业协会类社会组织。
      </p>
      <p>
        我会二十年来组织开展了多方面的学术研讨及相关活动，并取得了丰硕的成果，主要有：
      </p>
      <p>
        1、学会在文化管理学科一系列前沿课题方面取得了重要的具有前瞻性的突破。
      </p>
      <p>
        2、2000年秋天，“中国文化管理学（文化管理研究）”正式成为国家二级学科（在艺术科学下边，成为艺术科学的第七个二级学科）。
      </p>
      <p>
        3、2005年，为纪念安徒生诞辰二百周年，主办了全球十个原创项目之一、中国区唯一一个原创项目—“中国当代艺术邀请展”。
      </p>
      <p>
        4、2009年，我会与天津今晚传媒集团共同组织的第一个华夏小记者团登上祖国宝岛台湾。
      </p>
      <p>
        5、2009年6月，我会组织专家起草、审定的《中国组织文化管理体系测评标准1.0版》问世，在学界引起较大反响。
      </p>
      <p>
        6、2009年12月20日，为纪念毛泽东主席诞辰116周年，在北京国际会议中心举办了“纪念毛泽东主席诞辰116周年活动”，十届全国人大副委员长何鲁丽、顾秀莲，百岁革命老人王定国和老一辈无产阶级革命家的后代任远远、贺捷生等出席。
      </p>
      <p>
        7、2011年5月，与国家图书馆出版社合作出版《中国组织（企业）文化优秀成果案例集》第一卷。
      </p>
      <p>
        8、2011年11月24日，中国企业文化管理年会暨《中国企业文化管理测评标准2.0》（简称COCS标准2.0）发布，并由国家图书馆出版社出版。
      </p>
      <p>
        9、2011年，我会参加了民政部的社会组织评估工作，根据《社会组织评估管理办法》以及全国性社会组织评估实施办法的规定，经全国性社会组织评估委员会审议通过，中国文化管理学会被民政部评为“3A级全国性学术类社会组织”。2012年3月被文化部评为“文化部业务主管先进社会组织”。
      </p>
      <p>
        10、
        为认真学习贯彻党的十七届六中全会精神，推动社会主义文化大发展大繁荣，中国文化管理学会与中国文化报社、东方文化艺术院联合举办“学习六中全会精神、推动文化大发展大繁荣”公益有奖征文活动于2012年6月圆满结束。由全国政协副主席、中国文联主席孙家正题写书名，文化部政策法规司司长韩永进作序的《迎接文化的春天——“学习六中全会、推动文化大发展大繁荣”征文选集》现已由人民日报出版社正式出版，证书、奖金也已发放完毕。
      </p>
      <p>
        11、
        2012年，为全面记载党的十七大以来全国文化系统体制改革工作的伟大进程，宣传文化体制改革工作成果，上级主管部门文化部也对我会委以重任，授权编写文化部改革办主编的《十七大以来文化系统体制改革工作大事记和重要文献选编》；为推动演艺业健康有序发展，受文化部改革办的委托，进行“演艺企业评估体系研究”课题研究工作，目前已完成《全国演艺企业评估管理办法（报审稿）》、《关于演艺企业评估指标体系及说明（报审稿）》和《演艺企业评估指标细则（报审稿）》并正式上报文化部；为使转制企业在日趋激烈的市场竞争中站稳脚跟、实现长远发展，委托中国文化传媒集团和中国文化管理学会作为发起单位成立“全国转
        制企业改革发展联盟”。这既是对我们的信任，也是对我们的鞭策。
      </p>
      <p>
        12、
        2013年，我会申报的社会工作服务示范项目“和谐文化社区公益行”，获得“中央财政支持社会组织参与社会服务”专项资金拨款，构建和谐社区是构建和谐社会的基础性工程，社区建设是社会现代化发展的新载体、新平台、新动力，而社区文化建设是社区建设的本质，道德文化建设是社区文化建设的核心，国以人为本，人以德为本，德以孝为本，孝德教育是国人立根之本。该活动受益对象包括广大社区居民、物业服务企业、居（村）委会、街道办事处及其他社会组织，总计人数将达到近百万。活动希望通过亲近百姓的方式在社区生活中重塑社会道德体系和价值观，有助于形成和谐、友善、互助的人际关系和科学、健康、文明的生活方式，对
        建设和谐社区，构建和谐社会具有十分重要的意义。
      </p>
      <p>
        13、每年定期开展培训活动，先后举办了“中国非物质文化遗产保护工作培训班”、“全国文化馆、图书馆、博物馆岗位培训班”、“中国剧院团经营管理培训班”、“中国公共文化服务体系建设培训班”等，社会反响良好。
      </p>
      <br />
    </div>
    <h3
      @click="showBox(2)"
      :style="isShow == 0 ? 'position:static;' : 'bottom:50px;'"
    >
      组织架构 <img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div
      class="boxHide"
      ref="boxHide2"
      :style="isShow == 2 ? 'height:' + eleLength + 'px' : 'height:0px'"
    >
      <br />
      <p><strong>名誉主席：周铁农</strong></p>
      <p>
        第九届、十届全国政协副主席，第十一届全国人大常委会副委员长，协会四届理事会主席
      </p>
      <p>
        <strong>专家顾问委员会：</strong>蒋效愚 路建平孙希岳 杨正泉 陈虹 张晓林
        柳斌 高运甲 舒惠国
      </p>
      <p><strong>主席：梁国扬</strong></p>
      <p>
        第十一届全国人大常委会委员、华侨委员会副主任委员，政协十二届全国委员会常委，中共十八届中央委员
        会候补委员，全国台联原党组书记、会长
      </p>
      <p><strong>常务副主席：梁鑫华</strong>（法定代表人）</p>
      <p>
        中国大众音乐协会会长，中国社会组织促进会副会长，民革中央企业家联谊会副会长。国家一级作家
      </p>
      <p><strong>副主席：</strong>（按姓氏笔画排序）</p>
      <p>
        <strong>王俊昌</strong>
        国家安监总局党校教授、原常务副校长(正局级),安监总局书画协会主席
      </p>
      <p><strong>王海平</strong> 王海平北京市纪委原副书记、监察局原局长</p>
      <p>
        <strong>孙伟林</strong>
        中国社会组织促进会常务副会长，民政部国家社会组织管理局原局长
      </p>
      <p><strong>孙德全</strong> 中国作家协会创联部 原主任</p>
      <p>
        <strong>牟君发</strong> 中央政法委员会司改办、 政法研究所原巡视员、教授
      </p>
      <p><strong>李小磊</strong> 文化部产业司原副司长、巡视员</p>
      <p><strong>李新乐</strong> 中央统战部三局原巡视员</p>
      <p><strong>吴 杰</strong> 中国图书进出口（集团）总公司原副总经理</p>
      <p><strong>汪建德</strong> 中央文化管理干部学院 原副院长、教授</p>
      <p><strong>张卫航</strong> 公安部出入境管理局原政委</p>
      <p><strong>张 宁</strong> 西安市政协副主席</p>
      <p>
        <strong>陆耀儒</strong>
        文化部办公厅原副主任，文化部离退休中心原主任，中国少数民族美术促进会会长
      </p>
      <p><strong>陈捷延</strong> 华鼎国学研究基金会 秘书长</p>
      <p>
        <strong>赵东鸣</strong> 北京广电局原局长、党组书记
        北京奥组委文化活动部部长
      </p>
      <p><strong>徐国宝</strong> 全国人大正局级文化立法专家</p>
      <p>
        <strong>徐晓林</strong> 华中科技大学公共管理学院原院长、教授、博士生导师
      </p>
      <p><strong>常丕军</strong> 国家图书馆原副馆长、 党委副书记</p>
      <p><strong>梁潮平</strong> 中国民航局原党组成员、纪检组长</p>
      <p>
        <strong>解云天</strong>
        原水钢钢城有限责任公司党委书记兼董事长，协会企业文化管理专业委员会会长
      </p>
      <p><strong>黎晓宽</strong> 国家工商行政管理总局市场司原副司长、巡视员</p>
      <p><strong>秘 书 长：</strong> 崔丽丽</p>
      <p><strong>常务副秘书长：</strong> 于玲玲</p>
      <p>
        <strong>副秘书长：</strong> 林旭东 张鸿钧 徐建林 冷克明 崔云争 章 宾
        尹英希 王 腾
      </p>
    </div>
    <h3 @click="showBox(3)" :style="isShow == 0 ? 'position:static;' : ''">
      组织章程 <img src="../assets/ico/xIcon.png" width="30px" height="30px" />
    </h3>
    <div
      class="boxHide"
      ref="boxHide3"
      :style="isShow == 3 ? 'height:' + eleLength + 'px' : 'height:0px'"
    >
      <br />
      <p><strong>第一章 总则</strong></p>
      <p>
        第一条中国文化管理协会是由文化领域企事业单位、社会组织和对我国文化管理领域、文化行业有贡献、有影响的管理者、经营者、专家学者自愿结成的全国性、行业性社会团体，是非营利性社会组织。
      </p>
      <p>本会会员分布和活动地域全国。</p>
      <p>
        第二条本会的宗旨是：拥护党的领导，贯彻执行党的路线、方针、政策，高举中国特色社会主义伟大旗帜，坚持社会效益优先，为会员服务，为文化行业服务，为政府和社会服务，发挥桥梁和纽带作用，努力提高我国文化管理水平，提升文化自觉、增强文化自信，促进社会主义文化繁荣发展。
      </p>
      <p>
        本会遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，自觉加强诚信自律建设。
      </p>
      <p>
        第三条本会坚持中国共产党的全面领导，根据中国共产党章程的规定，设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。
      </p>
      <p>本会的登记管理机关是民政部,党建领导机关是中央和国家机关工委。</p>
      <p>
        本会接受登记管理机关、党建领导机关、有关行业管理部门的业务指导和监督管理。
      </p>
      <p>第四条本会负责人包括主席、副主席、秘书长。</p>
      <p>第五条本会的住所设在北京。</p>
      <p><strong>第二章 业务范围</strong></p>
      <p>第六条本会的业务范围：</p>
      <p>
        （一）根据国家有关法律、法规、政策，在政府和企业、有关行业和国外同行业及企业之间发挥桥梁和纽带作用，向政府有关部门反映情况，向会员传达贯彻党和国家的方针政策；
      </p>
      <p>
        （二）充分发挥行业协会指导、引领作用，经批准协助政府部门组织制定、修改文化行业的国家标准和专业标准以及文化行业的推荐性标准，推进标准的贯彻实施；制订文化行业行规行约，约束行业行为，提高行业自律性，提倡公平竞争，维护行业利益，提高行业工作效能，提升行业整体形象；
      </p>
      <p>
        （三）积极开展文化领域学术研究工作，组织文化管理及产业论坛讲座、学术研讨，策划、组织、参与国际性文化领域相关的学术活动；开展国内外文化领域的学术交流和合作；对影响文化行业发展的重大课题，向政府和有关部门提出意见和建议并积极参与其工作；
      </p>
      <p>
        （四）接受政府或相关单位委托，承担文化领域的调研工作、科研题目、项目评估咨询服务，经政府有关部门批准，承担标准制订、成果鉴定、资格评审、认证和信用评价工作；
      </p>
      <p>
        （五）组织开展文化领域业务交流，总结推广成功经验；积极探索行业发展新的理念、思路、途径；
      </p>
      <p>
        （六）注重人才建设，经政府有关部门批准开展文化艺术专业人才、经营管理人才、科技人才的培训和评价认定，不断提高会员及文化行业从业人员整体素质，创造人才辈出的良好局面；
      </p>
      <p>
        （七）积极开展对外文化交流工作，参与国家文化战略工程建设，推动横向文化交流、经济贸易合作与技术进步，与国外相关团体、机构建立友好往来关系，推动中华文化走出去；
      </p>
      <p>（八）依照有关规定，收集、编辑、出版行业有关刊物和资料等；</p>
      <p>
        （九）受政府部门委托承办或根据文化发展和社会需要，开展文化艺术相关展览展示展演活动；
      </p>
      <p>
        （十）维护会员单位的合法权益，开展咨询服务，承接会员委托事项，不断提高服务质量和服务水平；
      </p>
      <p>（十一）开展文化领域社会公益活动，推动公益事业发展；</p>
      <p>（十二）承办政府有关部门和机构委托办理的其它相关事项。</p>
      <p>
        业务范围中，属于法律法规规章规定须经批准的事项，依法经过批准后开展。
      </p>
      <p><strong>第三章 会员</strong></p>
      <p>第七条本会的会员为单位会员和个人会员。</p>
      <p>第八条拥护本会章程，符合下列条件的，可以自愿申请加入本会：</p>
      <p>（一）拥护本会的章程；</p>
      <p>（二）有加入本会的意愿；</p>
      <p>（三）文化领域企事业单位以及相关社会组织，可申请成为本会单位会员；</p>
      <p>（三）文化领域企事业单位以及相关社会组织，可申请成为本会单位会员；</p>
      <p>第九条会员入会的程序是：</p>
      <p>（一）提交入会申请书；</p>
      <p>（二）提交有关证明材料，包括：</p>
      <p>1.会员入会登记表；</p>
      <p>2.身份证复印件；</p>
      <p>3.职称证明复印件；</p>
      <p>4.单位法人证书/营业执照复印件。</p>
      <p>（三）由理事会授权的机构讨论通过；</p>
      <p>（四）由本会颁发会员证，并予以公告。</p>
      <p>第十条会员享有下列权利：</p>
      <p>（一）选举权、被选举权和表决权；</p>
      <p>（二）对本会工作的知情权、建议权和监督权；</p>
      <p>（三）参加本会活动并获得本会服务的优先权；</p>
      <p>（四）对本会工作的批评建议权和监督权；</p>
      <p>（五）入会自愿、退会自由。</p>
      <p>第十一条会员履行下列义务：</p>
      <p>（一）遵守本会的章程和各项规定；</p>
      <p>（二）执行本会的决议；</p>
      <p>（三）按规定交纳会费；</p>
      <p>（四）维护本会的合法权益；</p>
      <p>（五）向本会反映情况，提供有关资料。</p>
      <p>
        第十二条会员如有违反法律法规和本章程的行为，经理事会表决通过，给予下列处分：
      </p>
      <p>（一）警告；</p>
      <p>（二）通报批评；</p>
      <p>（三）暂停行使会员权利；</p>
      <p>（四）除名。</p>
      <p>第十三条会员退会须书面通知本会并交回会员证。</p>
      <p>第十四条会员有下列情形之一的，自动丧失会员资格：</p>
      <p>（一）1年不按规定交纳会费；</p>
      <p>（二）1年不按要求参加本会活动；</p>
      <p>（三）不再符合会员条件；</p>
      <p>（四）丧失民事行为能力；</p>
      <p>（五）个人会员被剥夺政治权利或列入社会信用黑名单；</p>
      <p>
        （六）单位会员被司法机关查处或被登记管理机关吊销、注销登记及列入社会信用黑名单。
      </p>
      <p>
        第十五条会员退会、自动丧失会员资格或者被除名后，其在本会相应的职务、权利、义务自行终止。
      </p>
      <p>
        第十六条本会置备会员名册，对会员情况进行记载。会员情况发生变动的，应当及时修改会员名册，并向会员公告。
      </p>
      <p><strong>第四章 组织机构</strong></p>
      <p><strong>第一节 会员代表大会</strong></p>
      <p>第十七条会员代表大会是本会的最高权力机构，其职权是：</p>
      <p>（一）制定和修改章程；</p>
      <p>（二）决定本会的工作目标和发展规划；</p>
      <p>
        （三）制定和修改会员代表、理事、监事、负责人产生办法，报党建领导机关备案；
      </p>
      <p>（四）选举和罢免理事、监事;</p>
      <p>（五）制定和修改会费标准；</p>
      <p>（六）审议理事会的工作报告和财务报告；</p>
      <p>（七）审议监事的工作报告；</p>
      <p>（八）决定名誉职务的设立；</p>
      <p>（九）决定名称变更事宜；</p>
      <p>（十）决定终止事宜；</p>
      <p>（十一）决定其他重大事宜。</p>
      <p>
        第十八条会员代表大会每届5年，每5年召开1次。因特殊情况需提前或者延期换届的，须由理事会表决通过，经党建领导机关审核同意后，报登记管理机关批准。延期换届最长不超过1年。
      </p>
      <p>本会召开会员代表大会，须提前15日将会议的议题通知会员代表。</p>
      <p>会员代表大会应当采用现场表决方式。</p>
      <p>
        第十九条经理事会或者本会 70
        %以上的会员代表提议，应当召开临时会员代表大会。
      </p>
      <p>
        临时会员代表大会由主席主持。主席不主持或不能主持的，由提议的理事会或会员代表推举本会一名负责人主持。
      </p>
      <p>
        第二十条会员代表大会须有2/3以上的会员代表出席方能召开，决议事项符合下列条件方能生效：
      </p>
      <p>
        （一）制定和修改章程，决定本会终止，须经到会会员代表2/3以上表决通过；
      </p>
      <p>
        （二）选举理事，当选得票数不得低于到会会员代表的1/2；罢免理事，须经到会会员代表1/2以上投票通过；
      </p>
      <p>
        （三）制定或修改会费标准，须经到会会员代表1/2以上无记名投票方式表决；
      </p>
      <p>（四）其他决议，须经到会会员代表1/2以上表决通过。</p>
      <p><strong>第二节 理事会</strong></p>
      <p>
        第二十一条理事会是会员代表大会的执行机构，在会员代表大会闭会期间领导本会开展工作，对会员代表大会负责。
      </p>
      <p>
        理事人数最多不得超过60人，不超过会员代表的1/3，不能来自同一会员单位。
      </p>
      <p>本会理事应当符合以下条件：</p>
      <p>
        （一）坚持中国共产党领导，拥护中国特色社会主义，坚决执行党的路线、方针、政策，具备良好的政治素质；
      </p>
      <p>（二）具备相应的专业知识、经验和能力，熟悉行业情况；</p>
      <p>（三）身体健康，能正常履责；</p>
      <p>（四）具有完全民事行为能力；</p>
      <p>（五）忠实、勤勉履行职责，维护本会和会员的合法权益；</p>
      <p>（六）无法律法规、国家政策规定，不得担任的其他情形。</p>
      <p>第二十二条理事的选举和罢免：</p>
      <p>
        （一）第一届理事由发起人商申请成立时的会员共同提名，报党建领导机关同意后，会员代表大会选举产生；
      </p>
      <p>
        （二）理事会换届，应当在会员代表大会召开前
        3个月，由理事会提名，成立由理事代表、监事代表、党组织代表和会员代表组成的换届工作领导小组；
      </p>
      <p>
        理事会不能召集的，由1/5以上理事、监事、本会党组织或党建联络员向党建领导机关申请，由党建领导机关组织成立换届工作领导小组，负责换届选举工作；
      </p>
      <p>
        换届工作领导小组拟定换届方案，应在会员代表大会召开前2
        个月报党建领导机关审核；
      </p>
      <p>经党建领导机关同意，召开会员代表大会，选举和罢免理事；</p>
      <p>
        （三）根据会员代表大会的授权，理事会在届中可以增补、罢免部分理事。最高不超过原理事总数的1/5。
      </p>
      <p>
        第二十三条每个理事单位只能选派一名代表担任理事。单位调整理事代表，由其书面通知本会，报理事会备案。
      </p>
      <p>第二十四条理事的权利:</p>
      <p>（一）理事会的选举权、被选举权和表决权；</p>
      <p>（二）对本会工作情况、财务情况、重大事项的知情权、建议权和监督权；</p>
      <p>（三）参与制定内部管理制度，提出意见建议；</p>
      <p>（四）向主席或理事会提出召开临时会议的建议权。</p>
      <p>
        第二十五条理事应当遵守法律、法规和本章程的规定，忠实履行职责、维护本会利益，并履行以下义务：
      </p>
      <p>（一）出席理事会会议，执行理事会决议；</p>
      <p>（二）在职责范围内行使权利，不越权；</p>
      <p>（三）不利用理事职权谋取不正当利益；</p>
      <p>（四）不从事损害本会合法利益的活动；</p>
      <p>
        （五）不得泄露在任职期间所获得的涉及本会的保密信息，但法律、法规另有规定的除外;
      </p>
      <p>（六）谨慎、认真、勤勉、独立行使被合法赋予的职权；</p>
      <p>（七）接受监事对其履行职责的合法监督和合理建议。</p>
      <p>第二十六条理事会的职权是：</p>
      <p>（一）执行会员代表大会的决议；</p>
      <p>（二）选举和罢免负责人；</p>
      <p>（三）决定名誉职务人选；</p>
      <p>（四）筹备召开会员代表大会，负责换届选举工作；</p>
      <p>（五）向会员代表大会报告工作和财务状况；</p>
      <p>
        （六）决定设立、变更和终止分支机构、代表机构、办事机构和其他所属机构；
      </p>
      <p>（七）决定副秘书长、各所属机构主要负责人的人选；</p>
      <p>（八）领导本会各所属机构开展工作；</p>
      <p>（九）审议年度工作报告和工作计划；</p>
      <p>（十）审议年度财务预算、决算；</p>
      <p>
        （十一）制定信息公开办法、财务管理制度、分支（代表）机构管理办法等重要的管理制度；
      </p>
      <p>（十二）决定本会负责人和工作人员的考核及薪酬管理办法；</p>
      <p>（十三）决定其他重大事宜。</p>
      <p>第二十七条理事会与会员代表大会任期相同，与会员代表大会同时换届。</p>
      <p>
        第二十八条理事会会议须有2/3以上理事出席方能召开，其决议须经到会理事2/3以上表决通过方能生效。
      </p>
      <p>理事2次不出席理事会会议，自动丧失理事资格。</p>
      <p>第二十九条负责人由理事会采取无记名投票方式从理事中选举产生。</p>
      <p>罢免负责人，须经到会理事2/3以上投票通过。</p>
      <p>
        第三十条选举负责人，按得票数确定当选人员，但当选的得票数不得低于总票数的2/3。
      </p>
      <p>
        第三十一条理事会每年至少召开1次会议，情况特殊的，可采用通讯形式召开。通讯会议不得决定负责人的调整。
      </p>
      <p>第三十二条经主席或者1/5的理事提议，应当召开临时理事会会议。</p>
      <p>
        主席不能主持临时理事会会议，由提议召集人推举本会一名负责人主持会议。
      </p>
      <p><strong>第三节 负责人</strong></p>
      <p>第三十三条本会负责人包括主席1名，副主席11-17名，秘书长1名。</p>
      <p>本会负责人应当具备下列条件：</p>
      <p>
        （一）坚持中国共产党领导，拥护中国特色社会主义，坚决执行党的路线、方针、政策，具备良好的政治素质；
      </p>
      <p>（二）遵纪守法，勤勉尽职，个人社会信用记录良好；</p>
      <p>
        （三）具备相应的专业知识、经验和能力，熟悉行业情况，在本会业务领域有较大影响；
      </p>
      <p>（四）身体健康，能正常履责，年龄不超过70周岁，秘书长为专职；</p>
      <p>（五）具有完全民事行为能力；</p>
      <p>（六）能够忠实、勤勉履行职责，维护本会和会员的合法权益；</p>
      <p>（七）无法律法规、国家政策规定不得担任的其他情形。</p>
      <p>
        主席、秘书长不得兼任其他社会团体的主席、秘书长，主席和秘书长不得由同一人兼任，并不得来自于同一会员单位。
      </p>
      <p>第三十四条本会负责人任期与理事会相同，连任不超过2届。</p>
      <p>聘任或者向社会公开招聘的秘书长任期不受限制，可不经过民主选举程序。</p>
      <p>第三十五条主席为本会法定代表人。</p>
      <p>
        因特殊情况，经主席推荐、理事会同意，报党建领导机关审核同意并经登记管理机关批准后，可以由副主席或秘书长担任法定代表人。聘任或向社会公开招聘的秘书长不得任本会法定代表人。
      </p>
      <p>法定代表人代表本会签署有关重要文件。</p>
      <p>本会法定代表人不兼任其他社团的法定代表人。</p>
      <p>
        第三十六条担任法定代表人的负责人被罢免或卸任后，不再履行本会法定代表人的职权。由本会在其被罢免或卸任后的20日内，报党建领导机关审核同意后，向登记管理机关办理变更登记。
      </p>
      <p>
        原任法定代表人不予配合办理法定代表人变更登记的，本会可根据理事会同意变更的决议，报党建领导机关审核同意后，向登记管理机关申请变更登记。
      </p>
      <p>第三十七条主席履行下列职责：</p>
      <p>（一）召集和主持理事会；</p>
      <p>（二）检查会员代表大会、理事会决议的落实情况；</p>
      <p>（三）向会员代表大会、理事会报告工作。</p>
      <p>
        主席应每年向理事会进行述职。不能履行职责时，由其委托或理事会推选一名副主席代为履行职责。
      </p>
      <p>第三十八条副主席、秘书长协助主席开展工作。秘书长行使下列职责：</p>
      <p>（一）拟订年度工作报告和工作计划，报理事会审议；</p>
      <p>（二）拟订年度财务预算、决算报告，报理事会审议；</p>
      <p>（三）拟订内部管理制度，报理事会批准；</p>
      <p>（四）协调各分支机构、代表机构开展工作；</p>
      <p>（五）主持办事机构开展日常工作；</p>
      <p>（六）提名副秘书长及所属机构主要负责人，交理事会决定；</p>
      <p>（七）决定专职工作人员的聘用；</p>
      <p>（八）处理其他日常事务。</p>
      <p>
        第三十九条会员代表大会、理事会会议应当制作会议纪要。形成决议的，应当制作书面决议，并由出席会议成员核签。会议纪要、会议决议应当以适当方式向会员通报或备查，并至少保存10年。
      </p>
      <p>
        理事、负责人的选举结果须在20日内报党建领导机关审核，经同意，向登记管理机关备案并向会员通报或备查。
      </p>
      <p><strong>第四节 监事</strong></p>
      <p>第四十条本会设监事1名。监事任期与理事任期相同，期满可以连任。</p>
      <p>第四十一条监事的选举和罢免：</p>
      <p>（一）由会员代表大会选举产生；</p>
      <p>（二）监事的罢免依照其产生程序。</p>
      <p>第四十二条本会的负责人、理事和本会的财务管理人员不得兼任监事。</p>
      <p>第四十三条监事行使下列职权：</p>
      <p>（一）列席理事会会议，并对决议事项提出质询或建议；</p>
      <p>
        （二）对理事、负责人执行本会职务的行为进行监督，对严重违反本会章程或者会员代表大会决议的人员提出罢免建议；
      </p>
      <p>（三）检查本会的财务报告，向会员代表大会报告监事的工作和提出提案；</p>
      <p>
        （四）对负责人、理事、财务管理人员损害本会利益的行为，要求其及时予以纠正；
      </p>
      <p>
        （五）向党建领导机关、行业管理部门、登记管理机关以及税务、会计主管部门反映本会工作中存在的问题；
      </p>
      <p>（六）决定其他应由监事审议的事项。</p>
      <p>第四十四条监事应当遵守有关法律法规和本会章程，忠实、勤勉履行职责。</p>
      <p>
        第四十五条监事可以对本会开展活动情况进行调查；必要时，可以聘请会计师事务所等协助其工作。监事行使职权所必需的费用，由本会承担。
      </p>
      <p>
        <strong>第五节 分支机构、代表机构</strong>
      </p>
      <p>
        <strong>第四十六条</strong
        >本会在本章程规定的宗旨和业务范围内，根据工作需要设立分支机构、代表机构。本会的分支机构、代表机构是本会的组成部分，不具有法人资格，不得另行制订章程，不得发放任何形式的登记证书，在本会授权的范围内开展活动、发展会员，法律责任由本会承担。<br />分支机构、代表机构开展活动，应当使用冠有本会名称的规范全称，并不得超出本会的业务范围。<br />
      </p>
      <p>
        <strong>第四十七条</strong
        >本会不设立地域性分支机构，不在分支机构、代表机构下再设立分支机构、代表机构。<br />
      </p>
      <p>
        <strong>第四十八条</strong
        >本会的分支机构、代表机构名称不以各类法人组织的名称命名，不在名称中冠以“中国”、“中华”、“全国”、“国家”等字样，应以“分会”、“专业委员会”、“工作委员会”、“专项基金管理委员会”、“代表处”、“办事处”等字样结束。<br />
      </p>
      <p>
        <strong>第四十九条</strong
        >分支机构、代表机构的负责人，年龄不得超过70周岁，连任不超过2届。<br />
      </p>
      <p>
        <strong>第五十条</strong
        >分支机构、代表机构的财务必须纳入本会法定账户统一管理。<br />
      </p>
      <p>
        <strong>第五十一条</strong
        >本会在年度工作报告中将分支机构、代表机构的有关情况报送登记管理机关。同时，将有关信息及时向社会公开，自觉接受社会监督。
      </p>
      <p>
        <strong>第六节 &nbsp;内部管理制度和矛盾解决机制</strong>
      </p>
      <p>
        <strong>第五十二条</strong
        >本会建立各项内部管理制度，完善相关管理规程。建立《会员管理办法》、《会员代表产生办法》、《会费管理办法》、《分支（代表）机构管理办法》等相关制度和文件。<br />
      </p>
      <p>
        <strong>第五十三条</strong
        >本会建立健全证书、印章、档案、文件等内部管理制度，并将以上物品和资料妥善保管于本会场所，任何单位、个人不得非法侵占。管理人员调动工作或者离职时，必须与接管人员办清交接手续。<br />
      </p>
      <p>
        <strong>第五十四条</strong
        >本会证书、印章遗失时，经理事会2/3以上理事表决通过，在公开发布的报刊上刊登遗失声明，可以向登记管理机关申请重新制发或刻制。如被个人非法侵占，应通过法律途径要求返还。<br />
      </p>
      <p>
        <strong>第五十五条</strong
        >本会建立民主协商和内部矛盾解决机制。如发生内部矛盾不能经过协商解决的，可以通过调解、诉讼等途径依法解决。
      </p>
      <p><strong>第五章 资产管理、使用原则</strong></p>
      <p><strong>第五十六条</strong>本会收入来源：</p>
      <p>（一）会费；</p>
      <p>（二）捐赠；</p>
      <p>（三）政府资助；</p>
      <p>（四）在核准的业务范围内开展活动、提供服务及培训的收入；</p>
      <p>（五）利息；</p>
      <p>（六）其他合法收入。</p>
      <p><strong>第五十七条</strong>本会按照国家有关规定收取会员会费。</p>
      <p>
        <strong>第五十八条 &nbsp;</strong
        >本会的收入除用于与本会有关的、合理的支出外，全部用于本章程规定的业务范围和非营利事业。
      </p>
      <p>
        <strong>第五十九条</strong
        >本会执行《民间非营利组织会计制度》，建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。
      </p>
      <p>
        <strong>第六十条</strong
        >本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或者离职时，必须与接管人员办清交接手续。
      </p>
      <p>
        <strong>第六十一条</strong
        >本会的资产管理必须执行国家规定的财务管理制度，接受会员代表大会和有关部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
      </p>
      <p>
        <strong>第六十二条 </strong
        >本会重大资产配置、处置须经过会员代表大会或者理事会审议。
      </p>
      <p>
        <strong>第六十三条</strong
        >理事会决议违反法律、法规或章程规定，致使社会团体遭受损失的，参与审议的理事应当承担责任。但经证明在表决时反对并记载于会议记录的，该理事可免除责任。
      </p>
      <p>
        <strong>第六十四条</strong
        >本会换届或者更换法定代表人之前必须进行财务审计。
      </p>
      <p>
        法定代表人在任期间，本社团发生违反《社会团体登记管理条例》和本章程的行为，法定代表人应当承担相关责任。因法定代表人失职，导致社会团体发生违法行为或社会团体财产损失的，法定代表人应当承担个人责任。
      </p>
      <p>
        <strong>第六十五条</strong
        >本会的全部资产及其增值为本会所有，任何单位、个人不得侵占、私分和挪用，也不得在会员中分配。
      </p>
      <p><strong> &nbsp; 第六章 &nbsp;信息公开与信用承诺</strong></p>
      <p>
        <strong>第六十六条 </strong
        >本会依据有关政策法规，履行信息公开义务，建立信息公开制度，及时向会员公开年度工作报告、第三方机构出具的报告、会费收支情况以及经理事会研究认为有必要公开的其他信息，及时向社会公开登记事项、章程、组织机构、接受捐赠、信用承诺、政府转移或委托事项、可提供服务事项及运行情况等信息。
      </p>
      <p>
        本会建立新闻发言人制度，经理事会通过，任命或指定3
        名负责人作为新闻发言人，就本组织的重要活动、重大事件或热点问题，通过定期或不定期举行新闻发布会、吹风会、接受采访等形式主动回应社会关切。新闻发布内容应由本会法定代表人或主要负责人审定，确保正确的舆论导向。
      </p>
      <p>
        <strong>第六十七条</strong
        >本会建立年度报告制度，年度报告内容及时向社会公开，接受公众监督。
      </p>
      <p>
        <strong>第六十八条</strong
        >本会重点围绕服务内容、服务方式、服务对象和收费标准等建立信用承诺制度，并向社会公开信用承诺内容。
      </p>
      <p><strong>第七章 &nbsp;章程的修改程序</strong></p>
      <p>
        <strong>第六十九条</strong
        >对本会章程的修改，由理事会表决通过，提交会员代表大会审议。
      </p>
      <p>
        <strong>第七十条</strong
        >本会修改的章程，经会员代表大会到会会员代表2/3以上表决通过后，报党建领导机关审核，经同意，在30日内报登记管理机关核准。
      </p>
      <p><strong>第八章 &nbsp;终止程序及终止后的财产处理</strong></p>
      <p>
        <strong>第七十一条</strong
        >本会终止动议由理事会提出，报会员代表大会表决通过。
      </p>
      <p>
        <strong>第七十二条</strong
        >本会终止前，应当依法成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。
      </p>
      <p>
        <strong>第七十三条</strong
        >本会经登记管理机关办理注销登记手续后即为终止。
      </p>
      <p>
        <strong>第七十四条</strong
        >本会终止后的剩余财产，在党建领导机关和登记管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业，或者捐赠给宗旨相近的社会组织。
      </p>
      <p><strong>第九章 附则</strong></p>
      <p>
        <strong>第七十五条 </strong
        >本章程经2019年7月7日第五届会员代表大会表决通过。
      </p>
      <p><strong>第七十六条</strong>本章程的解释权属本会的理事会。</p>
      <p><strong>第七十七条</strong>本章程自登记管理机关核准之日起生效。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "abouT",
  data() {
    return {
      isShow: 1,
      eleLength: 0,
    };
  },
  mounted() {
    this.eleLength = this.$refs["boxHide1"].scrollHeight;
  },
  methods: {
    showBox: function (num) {
      if (this.isShow != num) {
        this.eleLength = this.$refs["boxHide" + num].scrollHeight;
        this.isShow = num;
      } else {
        this.isShow = 0;
      }
    },
  },
};
</script>
<style scoped>
.aboutBody {
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.boxHide {
  height: 0;
  overflow: hidden;
}

.aboutBody_title {
  line-height: 70px;
  text-align: center;
}

/* .aboutBody img{
        display: block;
        margin: 0 auto;
    } */
.boxHide p {
  text-indent: 2em;
  line-height: 32px;
  padding: 0 40px;
}

.aboutBody h3 {
  font-size: 18px;
  line-height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 1px #000;
  cursor: pointer;
  border-left: 5px solid #289aa6;
}

.aboutBody h3 span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

@media screen and (max-width: 980px) {
  .aboutBody h3 {
    padding: 0 20px;
  }

  .aboutBody p {
    text-indent: 2em;
    line-height: 32px;
    padding: 0 0px;
    color: #333;
  }
}
</style>
