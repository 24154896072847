<!-- 列表页 -->
<template>
  <div class="main aboutBody">
    <h1 class="aboutBody_title">{{ columnName }}</h1>
    <hr />
    <div class="columnBody">
      <div class="columnItem_left">
        <div class="columnItem_left_1">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in swiperData"
                :key="item.id"
              >
                <img
                  class="article_img"
                  :src="item.imagesSrc"
                  :alt="item.title"
                  @click="goShow(item)"
                />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="columnItem_left_2">
          <h3 class="Hot">热点推荐</h3>
          <div class="hot_li" v-for="item in swiperData" :key="item.id" @click="goShow(item)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="columnItem">
        <div
          class="columnLi"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goShow(item)"
        >
          <span class="li_title_1">{{ item.title }}</span>
          <div class="li_title">
            <span class="li_title_2">
              <img
                :src="item.imagesSrc"
                :alt="item.title"
                v-if="item.imagesSrc != false"
              />
              <span>{{
                item.source.indexOf("http") != -1 ? "去看看" : item.context
              }}</span>
              <div class="li_time">
                <strong>{{ item.createTime.split(" ")[0] }}</strong
                ><br />{{ item.createTime.split(" ")[1] }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="pageTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        style="margin: 20px auto; width: fit-content"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper.js";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  name: "columnList",
  data() {
    return {
      columnName: "",
      pageTotal: 0,
      pageSize: 10,
      nowPage: 1,
      dataList: [],
      groupId: "",
      swiperData: "",
    };
  },
  created() {
    this.columnName = this.$route.query.data;
    this.groupId = this.$route.query.id;
    this.inquire();
    this.setSwiper();
  },
  mounted() {
    this._initSwiper();
  },
  methods: {
    goShow(item) {
      if (item.source.indexOf("http") == -1) {
        this.$router.push({ name: "showText", query: { data: item.id } });
      } else {
        window.open(item.source, "_blank");
      }
    },
    setSwiper() {
      let data = new FormData();
      data.append("title", "");
      data.append("startDate", "");
      data.append("endDate", "");
      data.append("pageNo", 1);
      data.append("pageSize", 10);
      data.append("columnId", 2);
      this.$axios
        .post("api/workbench/article/queryArticleByConditionForPage", data)
        .then((res) => {
          this.swiperData = res.data.articlePageInfo.list;
        });
    },
    inquire() {
      let data = new FormData();
      data.append("title", "");
      data.append("startDate", "");
      data.append("endDate", "");
      data.append("pageNo", this.nowPage);
      data.append("pageSize", this.pageSize);
      data.append("columnId", this.groupId);
      this.$axios
        .post("api/workbench/article/queryArticleByConditionForPage", data)
        .then((res) => {
          this.dataList = res.data.articlePageInfo.list;
          this.pageTotal = res.data.articlePageInfo.total;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.inquire();
    },
    handleCurrentChange(val) {
      this.nowPage = val;
      this.inquire();
    },
    _initSwiper() {
      setTimeout(() => {
        new Swiper(".swiper-container", {
          direction: "horizontal", // 垂直切换选项
          //mousewheel: true, //滚轮
          autoplay: {
            //自动开始
            delay: 4000, //时间间隔
            disableOnInteraction: true, //*手动操作轮播图后不会暂停*
          },
          loop: true, // 循环模式选项

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true, // 分页器可以点击
          },
        });
      }, 100);
    },
  },
};
</script>
<style scoped>
.aboutBody {
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.aboutBody_title {
  line-height: 70px;
  text-align: center;
}
.columnBody {
  display: flex;
}

.columnItem {
  flex-grow: 2;
  /* display: flex;
        flex-direction: column; */
}
.columnItem_left {
  min-width: 300px;
  height: 600px;
  box-sizing: border-box;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.columnItem_left_1 {
  width: 278px;
  height: 155px;
}
.article_img {
  width: 278px;
  height: 155px;
  border-radius: 5px;
  object-position: center center;
}
.columnItem_left_2 {
  flex: 1;
  box-sizing: border-box;
  padding-top: 10px;
  overflow: hidden;
}
.Hot {
  background-color: #289aa6;
  padding: 5px;
  color: #fff;
}
.Hot::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../assets/ico/hot.png");
  background-size: cover;
  vertical-align: middle;
  margin:0 5px;
}
.hot_li{
  border-bottom: #8b8b8b 1px dashed;
  padding: 0 5px;
  line-height: 36px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;
  color: #3d3d3d;
}
.hot_li:hover{
  background-color: #ececec;
}
.columnLi {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px dashed #8b8b8b;
}
.columnLi img {
  max-height: 70px;
  margin: 0 10px;
}
.columnLi:hover {
  background-color: #ddd;
}

.li_time {
  width: 80px;
  min-width: 80px;
  font-size: 0.8rem;
  color: #8b8b8b;
  text-align: center;
  line-height: 24px;
}

.li_title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px;
}

.li_title_1 {
  line-height: 32px;
  font-weight: 600;
  max-height: 32px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.li_title_2 {
  line-height: 24px;
  color: #565656;
  overflow: hidden;
  max-height: 72px;
  display: flex;
  justify-content: flex-end;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.li_title_2 span {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  flex: 1;
}
.page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  align-items: center;
}
.page span {
  margin: 5px;
  font-size: 0.9rem;
  color: #565656;
}
.page input {
  height: 30px;
  width: 30px;
  text-align: center;
  margin: 0 5px;
  outline: none;
}
.page .page_btn {
  padding: 0 10px;
  line-height: 30px;
  background: #289aa6;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  .columnItem_left {
    display: none;
  }
}
</style>
