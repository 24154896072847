<template>
  <div class="main aboutBody2">
    <h1 class="aboutBody_title">{{ title }}</h1>
    <p class="aboutBody_source">文章来源：<b>{{ source }}</b><span>|</span>{{ createTime }}</p>
    <hr />
    <br />
    <div class="aboutBody_txt" v-html="contents"></div>
    <br />
    <div class="accessory">
      <div class="accessory_1" v-for="item in accessoryList" :key="item.id" @click="downLoc(item)">
        <img src="../assets/ico/accessory.png" alt="附件">
        <p>{{ item.accessoryName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "showText",
  data() {
    return {
      index: "",
      title: "",
      contents:'',
      source:'',
      createTime:'',
      accessoryList:[],
    };
  },
  created() {
    this.index = this.$route.query.data;
  },
  mounted() {
    this.getIdArticle(this.index).then((res) => {
      console.log(res);
      this.title = Object.freeze(res.data.title);
      this.contents = Object.freeze(res.data.contents);
      this.source=Object.freeze(res.data.source);
      this.createTime=Object.freeze(res.data.createTime.split(' ')[0]);
      this.accessoryList=res.data.accessoryList
    });
  },
  methods: {
    getIdArticle(id) {
      let data = new FormData();
      data.append("id", id);
      return this.$axios.post("api/workbench/article/queryArticleById", data);
    },
    downLoc(item){
      this.downloadFile(item.accessorySrc)
    },
    downloadFile(url) {
      console.log(url);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', url );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
  },
};
</script>
<style >
.aboutBody2 {
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
.aboutBody_title {
  line-height: 70px;
  text-align: center;
}
.aboutBody_txt p {
  /* text-indent: 2em; */
  line-height: 36px;
  max-width: 1000px;
  margin: 0 auto;
  

}
.aboutBody_source{
  text-align: center;
  line-height: 40px;
    letter-spacing: 1px;
}
.aboutBody_source span{
  display: inline-block;
  margin: 0 10px;
}
.aboutBody_txt img{
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.el-image-viewer__img{
  width: auto !important;
}
.accessory{
 
  padding: 10px;
    box-sizing: border-box;
}
.accessory_1{
  margin: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 2px 0px #000;
    cursor: pointer;
}
.accessory_1:hover{
  background: #e8e8e8;
}
.accessory_1 img{
  width: 32px;
    padding: 5px;
}
</style>
