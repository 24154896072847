import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
axios.defaults.withCredentials = true
Vue.prototype.$axios = axios
Vue.prototype.$URL='https://cscc.ac.cn/'
// Vue.prototype.$URL='http://192.168.0.209:8089'
Vue.use(ElementUI);
Vue.config.productionTip = false
router.beforeEach(function(to, from, next) {
  if (to.meta.isLogin) {
    //页面是否登录
    if (sessionStorage.getItem("token")) {
      //本地存储中是否有token(uid)数据
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "adminLogin"
      });
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
