<!-- 首页头部底部，导航 -->
<template>
  <div class="bodyMain">
    <div class="nav">
      <div class="nav_main main">
        <button @click="goTop()">回到顶部</button>

        <button @click="routerLink('home')">首页</button>
      </div>
    </div>
    <div class="logo main">
      <img src="../assets/logo.png" alt="中国文化管理协会体育文化专业委员会" />
      <p class="logo_p1">中国文化管理协会</p>
      <p>体育文化专业委员会</p>
      <p>China Culture Administration Association Sport&Culture Committee</p>
    </div>
    <div class="search" style="display: none;">
      <input type="text" placeholder="请输入要搜索的内容" />
      <button>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2.75a7.25 7.25 0 015.63 11.82l4.9 4.9a.75.75 0 01-.98 1.13l-.08-.07-4.9-4.9A7.25 7.25 0 1110 2.75zm0 1.5a5.75 5.75 0 100 11.5 5.75 5.75 0 000-11.5z"
          ></path>
        </svg>
      </button>
    </div>
    <div class="nav" style="height: 50px; position: static">
      <div class="programa main">
        <!-- <span @click="routerLink('/')">
                    <img src="../assets/index.png" alt="首页">
                </span> -->
        <span @click="routerLink('about')">关于<br class="br">协会</span>
        <span @click="routerLink('councilLnfo')">关于<br class="br">本会</span>
        <span @click="routerLink('columnList', '本会公告',3)">本会<br class="br">公告</span>
        <span @click="routerLink('columnList', '赛事资讯',4)">赛事<br class="br">资讯</span>
        <span @click="routerLink('columnList', '考试培训',5)">考试<br class="br">培训</span>
        <span @click="routerLink('CertificateQuery')">证书<br class="br">查询</span>
      </div>
    </div>
    <router-view :key="$route.query.data"></router-view>
    <div class="footer">
      <div class="main footer_1">
        <p>Copyright © 2023 体育文化专业委员会</p>
        <p>联系我们:0755-82170955</p>
        <p>公安备案号:<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023072550号-1</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indeX",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    routerLink: function (url, data,id) {
      this.$router.push({ name: url, query: { data,id } });
    },
    goTop: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bodyMain {
  width: 100%;
}

.nav {
  width: 100%;
  height: 45px;
  background-color: #289aa6;
  position: sticky;
  top: 0;
  z-index: 10;
}

.nav_main {
  height: inherit;
  display: flex;
  justify-content: flex-end;
}

.nav_main button {
  border: none;
  background: none;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  cursor: pointer;
}

.logo {
  margin: 20px auto;
}

.logo img {
  display: block;
  margin: 0 auto;
  max-width: 260px;
}

.logo p {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}
.logo .logo_p1{
  font-size: 48px;
  line-height: 80px;
}

.logo p:last-child {
  font-size: 20px;
  line-height: 40px;
}

.search {
  margin: 15px auto;
  max-width: 700px;
  padding: 0 0px 0px 10px;
  display: flex;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
}

.search input {
  flex: 1;
  border: none;
  outline: none;
  height: 40px;
  font-size: 18px;
  background-color: transparent;
}

.search button {
  border: none;
  background: none;
  height: 100%;
  width: 45px;
  cursor: pointer;
}

.programa {
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.programa span {
  height: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  flex: 1;
  border-right: 1px solid #fff;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(to right, #33c3d3, #2ccee0) no-repeat right bottom;
  background-size: 100% 0px;
  transition: background-size 300ms;
  color: #fff;
}

.programa span:hover {
  background-position-x: left;
  background-size: 100% 100%;
}

.programa span:last-child {
  border-right: none;
}

.programa span img {
  width: 32px;
  height: 32px;
}
.footer {
  margin-top: 20px;
  width: 100%;

  background-color: #289aa6;
}
.footer_1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 50px;
}
.footer_1 p {
  padding: 0 5px;
  font-size: 14px;
}
.footer_1 p a{
  color: inherit;
  text-decoration: none;
}
.br{
  display: none;
}
@media screen and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) {
}

@media screen and (max-width: 980px) {
  .logo p {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.2rem;
  }
  .logo .logo_p1{
  font-size: 2rem;
  line-height: 2.6rem;
}
  .logo p:last-child {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .search {
    margin: 15px 10px;
    border-radius: 30px;
  }

  .logo img {
    max-width: 240px;
  }
  .programa span {
    font-size: 1rem;
    word-break: break-all;
  }
  .footer_1 {
    flex-direction: column;
    line-height: 30px;
  }
  .br{
    display: block;
  }
}
</style>
