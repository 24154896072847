<template>
  <div class="main aboutBody">
    <div class="loginBox" @keyup.enter="login()">
      <h2 class="boxTitle">网站管理后台</h2>
      <input
        type="text"
        placeholder="账号"
        class="boxInput"
        v-model="userName"
      />
      <input
        type="password"
        placeholder="密码"
        class="boxInput"
        v-model="passWord"
      />
      <input type="button" value="登录" class="boxBtn" @click="login()"  />
    </div>
  </div>
</template>
<script>
export default {
  name: "showText",
  data() {
    return {
      userName: "",
      passWord: "",
    };
  },
  mounted() {},
  methods: {
    login() {
      let that=this
      let data = new FormData();
      data.append("loginAct", this.userName);
      data.append("loginPwd", this.passWord);
      if (this.userName != "" && this.passWord != "") {
        this.$axios
          .post("api/settings/user/adminLogin", data)
          .then((res) => {
            console.log(res)
            if (res.status == 200) {
              if (res.data.code === "1") {
                window.sessionStorage.setItem("token", "111222333");
                that.$router.push({name:'admin'})
              } else {
                alert(res.data.message);
              }
            } else {
              alert(res.data.code + "服务端异常");
            }
          })
      }
    },
  },
};
</script>
<style scoped>
.aboutBody {
  position: fixed;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.boxTitle {
  text-align: center;
}
.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  max-width: 600px;
  background-color: #289aa6;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 5px;
  box-sizing: border-box;
}
.loginBox .boxInput {
  display: block;
  margin: 20px auto;
  max-width: 420px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0px 10px;
  box-sizing: border-box;
}
.boxBtn {
  display: block;
  margin: 20px auto;
  width: 80px;
  height: 32px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
