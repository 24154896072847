<template>
  <div class="main aboutBody">
    <div class="backstageLeft">
      <p class="left_p">栏目</p>
      <ul class="backstageLeft_ul">
        <li @click="postZhengshu('证书')" :class="group == '证书' ? 'onS' : ''">
          上传证书
        </li>
        <li @click="postZhengshu('聘书')" :class="group == '聘书' ? 'onS' : ''">
          上传聘书
        </li>
        <li
          v-for="(item, index) in groupList"
          :key="index"
          @click="postGroup(item.id)"
          :class="group == item.id ? 'onS' : ''"
        >
          {{ item.columnName }}
        </li>
      </ul>
    </div>
    <div class="backstageRight" v-show="isCertificate">
      <el-row>
        <h2 style="line-height: 50px">当前：{{ showGroupText() }}</h2>
        <el-input
          v-model="searchTitle"
          placeholder="标题搜索"
          style="width: 200px; margin-right: 10px"
        ></el-input>
        <el-date-picker
          v-model="searchDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="margin-right: 10px"
        >
        </el-date-picker>
        <el-button @click="inquire()">查询</el-button>
        <el-button type="primary" @click="reset()">重置</el-button>
        <el-button @click="centerDialogVisible = true">发布文章</el-button>
      </el-row>
      <el-table ref="filterTable" :data="tableData" style="width: 100%">
        <el-table-column
          prop="weight"
          label="排序"
          width="90"
          column-key="weight"
        >
          <template slot-scope="scope">
            <input
              type="text"
              v-model="scope.row.weight"
              class="rightInput"
              @blur="editWeight(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="source" label="来源" width="180">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发布时间"
          width="180"
          column-key="createTime"
        ></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="pageTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        style="margin: 20px auto; width: fit-content"
      >
      </el-pagination>
      <el-dialog
        title="编辑文章"
        :visible.sync="centerDialogVisible"
        :before-close="noSubForm"
        width="90%"
        centerDialogVisible
        :modal="false"
      >
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="文章标题" label-width="90px" prop="title">
            <el-input v-model="form.title" autocomplete="on"></el-input>
          </el-form-item>
          <el-form-item label="来源" label-width="90px" prop="username">
            <el-input v-model="form.username" autocomplete="on"></el-input>
          </el-form-item>
          <el-form-item label="栏目" label-width="90px" prop="group">
            <el-select v-model="form.group" placeholder="请选择">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.columnName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加附件" label-width="90px" prop="fileList" v-if="isUpdata">
            <el-upload
              class="upload-demo"
              :action="$URL + 'api/workbench/accessory/uploadAccessory'"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              name="accessoryFile"
              :data="{'articleId':editId}"
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="form.fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">上传文件不超过10MB</div>
            </el-upload>
          </el-form-item>

          <div class="editor_admin" v-if="centerDialogVisible">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="form.text"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
            />
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="noSubForm()">取 消</el-button>
          <el-button type="primary" @click="subForm()">提 交</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="backstageRight" v-show="!isCertificate">
      <el-row>
        <el-upload
          class="upload-demo"
          style="display:inline-block"
          :action="
            group == '证书'
              ?  'api/workbench/certificate/importCertificate'
              :  'api/workbench/appointment/importAppointment'
          "
          :limit="1"
          :name="group == '证书'?'certificateFile':'appointmentFile'"
          :on-success="updataExecl"
          :show-file-list="false"
        >
          <el-button>上传</el-button> </el-upload
        >&nbsp;
        <el-input
          v-model="searchName"
          placeholder="姓名"
          style="width: 200px; margin-right: 10px"
        ></el-input>
        <el-input
          v-model="searchNumber"
          placeholder="身份证"
          style="width: 200px; margin-right: 10px"
        ></el-input>
        <el-button type="primary" @click="postCertificate()">查询</el-button>
      </el-row>
      <el-table :data="certificateData" stripe style="width: 100%">
        <el-table-column
          v-for="(item, index) in certificateRule"
          :key="index"
          :prop="item.prop"
          :label="item.propName"
        >
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <img :src="getImgUrl(scope.row)" alt="" width="30px" height="30px" style="vertical-align: middle;">
            <el-button
              size="mini"
              type="danger"
              @click="deleteZhengshu(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="CertificateTotal"
        @size-change="CertificateSize"
        @current-change="CertificateChange"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        style="margin: 20px auto; width: fit-content"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { DomEditor } from "@wangeditor/editor";
import "@wangeditor/editor/dist/css/style.css";
export default {
  name: "admiN",
  components: { Editor, Toolbar },
  data() {
    return {
      //证书
      isCertificate: "wenzhang",
      searchName: "",
      searchNumber: "",
      certificateData: [],
      certificateRule: [],
      CertificateTotal:0,
      CertificatepageSize:10,
      nowCertificate:1,
      //证书
      groupList: [],
      tableData: [],
      searchTitle: "",
      searchDate: "",
      group: 1,
      nowPage: 1,
      pageSize: 10,
      pageTotal: 0,
      isUpdata: false,
      centerDialogVisible: false,
      form: {
        title: "",
        username: "",
        datatime: new Date(),
        text: "",
        group: "",
        imgArr: [],
        //上传文件
        fileList: [],
      },
      editId: "",
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        username: [
          { required: true, message: "请输入文章来源", trigger: "blur" },
        ],
        group: [
          { required: true, message: "请选择发布文章栏目", trigger: "blur" },
        ],
      },
      //编辑器
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            fieldName: "articleImgFile",
            server:  "api/workbench/article/uploadArticleImg",
            allowedFileTypes: ["image/*"],
            maxFileSize: 1 * 1024 * 1024,
            customInsert: this.updataImg,
          },
          // 继续其他菜单配置...
        },
      },
      mode: "default", // or 'simple'
      //编辑器
    };
  },
  created() {
    window.addEventListener("storage", function () {
      //这个e返回的是哪个值被更改，更改后的值跟更改之前的值
      if (window.sessionStorage.getItem("token")) {
        //这里是判断本地存储adminToken存在的时候写的逻辑
      } else {
        location.reload();
        //这里是判断本地存储adminToken不存在的时候写的逻辑，这里可以让他跳转到登陆页面
      }
    });

    this.$axios
      .post("api/workbench/column/queryAllColumn")
      .then((res) => {
        if (res.status == 200 ) {
          this.groupList = res.data;
        } else {
          console.log(res);
          alert("服务器异常");
        }
      })
      .catch((res) => {
        alert(res);
      });
  },
  mounted() {
    this.postGroup(1);
  },
  methods: {
    //上传证书聘书
    updataExecl(res){
      if(res.message==null){
        this.$message({
            message: "上传成功",
            type: "success",
          });
        this.postCertificate()
      }else{
        this.$message.error('上传失败'+res.message)
      }
    },
    CertificateSize(val) {
      this.CertificatepageSize = val;
      this.postCertificate()
    },
    CertificateChange(val) {
      this.nowCertificate = val;
      this.postCertificate()
    },
    //证书查看的点击事件
    postZhengshu(str) {
      this.isCertificate = false;
      this.group = str;
      this.CertificateTotal=0
     this.CertificatepageSize=10;
      this.nowCertificate=1;
      if (str == "证书") {
        this.certificateRule = Object.freeze([
          { prop: "name", propName: "姓名" },
          { prop: "trainingDate", propName: "培训日期" },
          { prop: "idNo", propName: "身份证号" },
          { prop: "issueDate", propName: "发证时间" },
          { prop: "professionalStandara", propName: "专业标准" },
          { prop: "cerNo", propName: "证书编号" },
        ]);
      } else if (str == "聘书") {
        this.certificateRule = Object.freeze([
          { prop: "name", propName: "姓名" },
          { prop: "nameEn", propName: "拼音" },
          { prop: "idNo", propName: "身份证号" },
          { prop: "term", propName: "聘期" },
          { prop: "cerNo", propName: "证书编号" },
        ]);
      }
      this.postCertificate();
    },
    //删除证书聘书数据
    deleteZhengshu(item) {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteCertificate(item.id).then((res) => {
            if (res.data.code == "1") {
              this.postCertificate();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //请求证书聘书数据
    postCertificate() {
      let url = "";
      if (this.group == "证书") {
        url = "api/workbench/certificate/queryCertificateByConditionForPage";
      } else if (this.group == "聘书") {
        url = "api/workbench/appointment/queryAppointmentByConditionForPage";
      }
      let data = new FormData();
      data.append("name", this.searchName);
      data.append("idNo", this.searchNumber);
      data.append("pageNo", this.nowCertificate);
      data.append("pageSize", this.CertificatepageSize);
      this.$axios.post(url, data).then((res) => {
        if (this.group == "证书") {
          this.certificateData =Object.freeze(res.data.certificatePageInfo.list) ;
          this.CertificateTotal= res.data.certificatePageInfo.total
        } else if (this.group == "聘书") {
          this.certificateData =Object.freeze( res.data.appointmentPageInfo.list);
          this.CertificateTotal=res.data.appointmentPageInfo.total
        }
      });
    },
    //根据id删除证书聘书
    deleteCertificate(id) {
      let url = "";
      if (this.group == "证书") {
        url = "api/workbench/certificate/deleteCertificateByIds";
      } else if (this.group == "聘书") {
        url = "api/workbench/appointment/deleteAppointmentByIds";
      }
      let data = new FormData();
      data.append("ids", id);
      return this.$axios.post(url, data);
    },
  //返回头像路径
    getImgUrl(data){
      if(data.imgUrl){
        return 'https://cscc.ac.cn'+data.imgUrl+'?='+new Date().getTime()
      }else{
        return 'https://cscc.ac.cn/img/juryImages/'+data.name+data.idNo.substr(-4)+'.jpg?='+new Date().getTime()
      }
        
      
    },
    //栏目的点击事件
    postGroup(id) {
      this.isCertificate = true;
      this.group = id;
      this.form.group = id;
      this.inquire();
    },
    //编辑请求数据
    handleEdit(data) {
      let that = this;
      this.isUpdata = true;
      this.getIdArticle(data.id)
        .then((res) => {
          if (res.status == 200  ) {
            that.editId = data.id;
            that.form.title = res.data.title;
            that.form.username = res.data.source;
            that.form.group = res.data.columnId;
            that.form.datatime = res.data.releaseTime;
            that.form.text = res.data.contents;
            that.centerDialogVisible = true;
           if(res.data.accessoryList.length!=0){
            for(let i=0;i<res.data.accessoryList.length;i++){
              this.form.fileList.push({name:res.data.accessoryList[i].accessoryName,url:res.data.accessoryList[i].accessorySrc})
            }
           }
          }
        })
        .catch(() => {
          this.$message.error("接口调用失败");
        });
    },
    showGroupText() {
      for (let i = 0; i < this.groupList.length; i++) {
        if (this.group == this.groupList[i].id) {
          return this.groupList[i].columnName;
        }
      }
    },
    //查询请求
    inquire() {
      let data = new FormData();
      data.append("title", this.searchTitle);
      data.append("startDate", this.searchDate[0] ? this.searchDate[0] : null);
      data.append("endDate", this.searchDate[1] ? this.searchDate[1] : null);
      data.append("pageNo", this.nowPage);
      data.append("pageSize", this.pageSize);
      data.append("columnId", this.group);
      this.$axios
        .post("api/workbench/article/queryArticleByConditionForPage", data)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.articlePageInfo.list;
            this.pageTotal = res.data.articlePageInfo.total;
          }
        })
        .catch(() => {
          this.$message.error("接口调用失败");
        });
    },
    //发布文章请求
    publishArticle(url) {
      let data = new FormData();
      data.append("title", this.form.title);
      data.append("imagesSrc", this.form.imgArr.toString());
      data.append("source", this.form.username);
      data.append("contents", this.form.text);
      data.append("context", this.html);
      data.append("columnId", this.form.group);
      return this.$axios.post(url, data);
    },
    //根据id查询文章请求
    getIdArticle(id) {
      let data = new FormData();
      data.append("id", id);
      return this.$axios.post("api/workbench/article/queryArticleById", data);
    },
    //删除文章的请求
    deleteArticle(id) {
      let data = new FormData();
      data.append("id", id);
      return this.$axios.post("api/workbench/article/deleteArticleById", data);
    },
    reset() {
      this.searchDate = "";
      this.searchTitle = "";
      this.inquire();
    },
    subForm() {
      this.html = this.editor.getText();
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isUpdata == true) {
            let data = new FormData();
            data.append("id", this.editId);
            data.append("title", this.form.title);
            data.append("imagesSrc", this.form.imgArr.toString());
            data.append("context", this.html);
            data.append("source", this.form.username);
            data.append("contents", this.form.text);
            data.append("columnId", this.form.group);
            this.$axios
              .post("api/workbench/article/saveEditArticle", data)
              .then((res) => {
                if (res.data.code == "1") {
                  this.noSubForm();
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else {
            this.publishArticle("api/workbench/Article/creatArticle").then(
              (res) => {
                if (res.data.code == "1") {
                  this.noSubForm();
                  this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    editWeight(item) {
      let data = new FormData();
      data.append("id", item.id);
      data.append("weight", item.weight);
      this.$axios
        .post("api/workbench/article/saveEditArticleWeight", data)
        .then(() => {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //重置发布文章的表单
    noSubForm() {
      this.editor.clear();
      this.centerDialogVisible = false;
      this.form = {
        title: "",
        username: "",
        datatime: new Date(),
        text: "",
        group: this.group,
        imgArr: [],
        //上传文件
        fileList: [],
      };
      this.html = "";
      this.isUpdata = false;
      this.editId = "";
      this.inquire();
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteArticle(data.id).then((res) => {
            if (res.data.code == "1") {
              this.inquire();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.inquire();
    },
    handleCurrentChange(val) {
      this.nowPage = val;
      this.inquire();
    },
    //编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.toolbarConfig = {
        excludeKeys: ["insertVideo", "uploadVideo", "group-video"],
      };
    },
    updataImg(res, insertFn) {
      this.form.imgArr.push(res.message);
      insertFn( res.message, "未知错误",  res.message);
    },
    //编辑器
    //上传文件
    handleRemove(file, fileList) {
      console.log(file, fileList,'删除了');
      let data=new FormData()
      data.append('accessorySrc',file.url)
      this.$axios.post('api/workbench/accessory/deleteAccessoryByPath',data).then((res)=>{
        console.log(res);
      }).catch(()=>{
        this.$message.error('删除失败')
      })
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传文件
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style scoped>
.aboutBody {
  position: fixed;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
}
.backstageLeft {
  width: 300px;
  height: 100%;
  background-color: #289aa6;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.backstageRight {
  flex: 1;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  overflow: auto;
}
.backstageLeft_ul {
  display: flex;
  flex-direction: column;
}
.backstageLeft_ul li {
  line-height: 40px;
  border-bottom: 1px solid #045c66;
  padding: 0 10px;
  cursor: pointer;
}
.backstageLeft_ul li:first-child {
  border-top: 1px solid #045c66;
}
.left_p {
  line-height: 30px;
  font-weight: 900;
  padding-left: 10px;
}
.rightInput {
  border: none;
  text-align: center;
  width: 30px;
  outline: none;
  line-height: 30px;
}
.onS {
  background-color: #045c66;
  color: #fff;
}
.editor_admin {
  border: 1px solid #ddd;
}
@media screen and (max-width: 980px) {
  .backstageLeft {
    width: 30vw;
    min-width: 30vw;
  }
}
</style>
