import Vue from "vue";
import VueRouter from "vue-router";
import home from "../components/home.vue";
import about from "../components/about.vue";
import columnList from "../components/columnList.vue";
import councilLnfo from "../components/councilLnfo.vue";
import CertificateQuery from "../components/CertificateQuery.vue";
import showText from "../components/showText.vue";
import adminLogin from "../components/adminLogin.vue";
import admiN from "../components/admin.vue";
import NotFoundComponent from "../components/NotFoundComponent.vue";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
        path: "/",
        name: "home",
        component: home,
        //主页
    },
    {
        path: "/about",
        name: "about",
        component: about,
        //协会介绍
    },
    {
        path: "/columnList",
        name: "columnList",
        component: columnList,
        //列表页
    },
    {
        path: "/councilLnfo",
        name: "councilLnfo",
        component: councilLnfo,
        //关于委员会
    },
    {
        path: "/CertificateQuery",
        name: "CertificateQuery",
        component: CertificateQuery,
        //证书查询
    },
    {
        path: "/showText",
        name: "showText",
        component: showText,
        //详情页
    },
    {
        path: "/adminLogin",
        name: "adminLogin",
        component: adminLogin,
        //后台登陆页
    },
    {
        path: "/cscc-Certificate-admin",
        name: "admin",
        meta: {
            isLogin: true,
        },
        component: admiN,
        //后台页
    }, { path: '/:pathMatch(.*)', component: NotFoundComponent }
];

const router = new VueRouter({
    // 把常量routes放进来
    routes,
    mode: "history",
    // mode: "hash",
    base: process.env.BASE_URL
});

// 导出router
export default router;